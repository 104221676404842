.navbar {
  position: fixed;
  top: 0;
  padding: 20px 0;
  width: 100%;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navbar-scrolled {
  top: 0;
  padding: 0px;
  z-index: 2;
  box-shadow: 0 2px 5px rgba(52, 73, 103, 0.2);
}

.text {
  margin: 0;
}

.logo_icon {
  margin: 15px;
  width: auto;
  height: 30px;
}

.search-mode {
  display: flex;
  padding: 0px;
  flex-direction: row;
  margin: 15px;
  font-size: 10px;
}

.button-mode {
  display: flex;
  width: 38px;
  height: 38px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.dark-mode-back {
  background-color: #0a192f;
}
.light-mode-back {
  background-color: #f1f4fd;
}

.search-button {
  display: flex;
  height: 38px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
  padding-left: 15px;
}

.placeholder {
  margin-left: 10px;
  border: none; /* Esto elimina los bordes */
  outline: none; /* Esto elimina el estilo de foco */
  background: transparent; /* Esto hace que el fondo sea transparente */
  color: #9fa4b5; /* Esto hace que el texto ingresado sea transparente */
  font-size: 16px;

  /* Esto cambia el color del placeholder */
}
input::placeholder {
  color: #9fa4b5;
}
