* {
  font-family: Arial, Helvetica, sans-serif;
  padding: 0;
  margin: 0;
}
.App {
  background-color: blue;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.back-component {
  display: flex;
  flex-direction: column;
  margin-top: 13vh;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  padding: 10px;
  min-height: 100vh;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
