.componentList {
  width: 100%;
}

.element-list {
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 10px;
}

.font-title {
  font-weight: bold;
  font-size: 18px;
  margin: 0;
  margin-left: 20px;
  margin-top: 10px;
}

.info-app {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  margin-left: 10px;
}

.title-app {
  margin: 0;
  font-weight: bold;
  font-size: 0.95em;
  line-height: 1.2;
}

.stats {
  display: flex;
  align-items: center;
}

.image-app {
  width: 60px;
  height: auto;
  border-radius: 15px;
}

.tag {
  color: #9fa4b5;
  margin-left: 3px;
  margin-right: 3px;
  font-size: 14px;
}

.data-info {
  font-size: 14px;
  margin-right: 3px;
}

.spected {
  display: flex;
  align-items: center;
}
.spected2 {
  display: flex;
  align-items: center;
}

.spected:after {
  content: '';
  display: inline-block;
  height: 20px;
  width: 1px;
  background-color: #d6dfea;
  margin: 0 5px;
}

.button-go {
  display: flex;
  align-items: center;
}

.style-button {
  background-color: #6169ff;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 20px;
}
