.componentList-c {
  overflow: hidden;
  border-radius: 20px;
  height: 170px;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.image-app-c {
  width: 80px;
  height: auto;
  border-radius: 20px;
}

.element-list-c {
  display: flex;
  max-width: 800px;
  background-color: #0000;
  height: 170px;
  border-radius: 20px;
  overflow: hidden;
}

.prueba {
  max-width: 200px;
}

.info-apps {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  width: 100;
  z-index: 2;
  bottom: 20px;
  padding-left: 15px;
  align-items: center;
  width: 100%;
}

.date-app-c {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  height: 100%;
}

.base-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50%;
  width: 100;
  overflow: hidden;
  margin-left: 20px;
}

.jack-in-thebox {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-end;
  padding-bottom: 15px;
  padding-left: 15px;
}
.jack-in-thebox-noblur {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-end;
  padding-bottom: 15px;
  padding-left: 15px;
}

.title {
  font-weight: bold;
  font-size: 1em;
  color: #ffff;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
}

.rating {
  display: flex;
  background-color: #6169ff;
  font-size: 0.7em;
  align-items: center;
  margin-left: 10px;
  padding: 3px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 20px;
}

.rating-text {
  font-weight: bold;
  margin-left: 3px;
  color: #ffff;
}

.title-rating {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.description-text {
  font-size: 0.8em;
  margin-right: 10px;
  font-weight: bold;
  color: #ffff;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limita el texto a 3 líneas */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cover-image {
  position: absolute;
}

@media (max-width: 1024px) {
  .element-list-c {
    max-width: 100%;
  }
}
