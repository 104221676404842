.componentList {
  width: 100%;
}

.scroll-x {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  border-radius: 10px;
}

.scroll-x::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scroll-x::-webkit-scrollbar-track {
  background: transparent;
}

.scroll-x::-webkit-scrollbar-thumb {
  background: #d6dfea;
  border-radius: 50px;
}

.scroll-x::-webkit-scrollbar-track {
  background: #f1f5f9;
}

.scroll-xx {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  border-radius: 10px;
}

.scroll-xx::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scroll-xx::-webkit-scrollbar-track {
  background: transparent;
}

.scroll-xx::-webkit-scrollbar-thumb {
  background: #172a46;
  border-radius: 50px;
}

.font-title {
  font-weight: bold;

  font-size: 18px;
  margin: 0;
  margin-left: 20px;
  margin-top: 10px;
}

.element-list-w {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 10px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 15px;
  margin: 5px;
  margin-bottom: 10px;
  max-width: 80px;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
}

.color-a {
  background-color: #172a46;
  color: #ced8f8;
}

.color-b {
  background-color: #ffff;
  color: #344b67;
}

.info-app-w {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-top: 5px;
}

.tag {
  color: #9fa4b5;
  margin-left: 3px;
  margin-right: 3px;
  font-size: 14px;
}

.data-info {
  font-size: 14px;
  margin-right: 3px;
}

.image-app-w {
  width: 50px;
  height: auto;
  border-radius: 10px;
}

.title-app-w {
  font-size: 0.7em;
  margin: 0;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 80px;
  margin-top: 8px;
}

.data-info-w {
  font-size: 0.6em;
  margin-left: 5px;
  color: #9fa4b5;
  font-weight: 600;
}
