
.header {
    padding-top: 25px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
}

.close {
    background-color: #6169ff;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 200px;
}

.text {
    display: flex;
    color: #9fa4b5;
    font-size: 18px;
    margin-left: 10px;
}
.textBusqueda {
    margin-left: 5px;
    color: #344b67;
    font-weight: bold;
}

.divider {
    height: 2px;
    width: 100%;
    background-color: #d6dfea;
    margin-bottom: 20px;
}