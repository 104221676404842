.totally {
  z-index: 2;
  position: absolute;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  animation: fade-in 2s fade-in-out;
}

.cover {
  background-color: #2f65ff;
  height: 100%;
  width: 100%;
}
.app-cover {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  height: 40%;
}

.modal-content {
  display: flex;
  flex-direction: column;
  margin-top: 13vh;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  min-height: 100vh;
  animation: slide-up 1s ease-in-out;
  overflow: hidden;
}

.button-contener {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 400px;
  align-items: center;
  justify-content: center;
  background-color: #6169ff;
  position: absolute;
  right: 20px;
  margin-top: 25px;
}

.Button-exit {
  color: #485475;
  font-size: 30px;
}

.component {
  width: 100%;
  height: 100vh;
  border-top-left-radius: 50px;
  border-radius: 40px;
  margin-top: -40px;
  z-index: 3;
}

.box-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  width: 100%; /* Establece un ancho máximo estático */
  overflow-x: auto;
}

.box-data::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.box-data::-webkit-scrollbar-track {
  background: transparent;
}

.box-data::-webkit-scrollbar-thumb {
  background: #d6dfea;
  border-radius: 50px;
}

.box-data::-webkit-scrollbar-track {
  background: #f1f5f9;
}

.box-data-d {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  width: 100%; /* Establece un ancho máximo estático */
  overflow-x: auto;
}

.box-data-d::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.box-data-d::-webkit-scrollbar-track {
  background: transparent;
}

.box-data-d::-webkit-scrollbar-thumb {
  background: #0a192f;
  border-radius: 50px;
}

.cabecera_modal {
  margin-top: -23px;
}
.data_top {
  margin-left: 10px;
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: 20px;
  margin-top: 20px;
}
.data_left {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.data_right {
  background-color: #6169ff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border-radius: 100px;
  width: 40px;
  height: 40px;
}

.contener {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 300px;
  height: 50px;

  padding: 20px;
  position: relative; /* Añade esta línea para posicionar el separador */
}

.contener-2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 300px;
  height: 60px;
  color: #a6b0cf;
  padding: 10px;
  position: relative; /* Añade esta línea para posicionar el separador */
}

.contener::after {
  content: '';
  position: absolute;
  top: 0;
  left: 100%; /* Cambia "right" a "left" para que aparezca al lado */
  height: 30%;
  width: 1px; /* Ancho de la línea */
  background-color: rgba(0, 0, 0, 0.15); /* Color de la línea */
  transform: translateY(100%); /* Centra verticalmente la línea */
}

/* Agrega un separador de línea entre cada .contener */
.contener + .contener {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.description-tex-2 {
  padding: 20px;
  font-size: 1em;
}

.endurance {
  font-size: 0.7em;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 3px;
  color: #9fa4b5;
}

.local-text {
  font-size: 0.85em;
}

.local-text-2 {
  font-weight: 400;
  font-size: 0.85em;
}

.dot-plus {
  display: flex;
  border: 1px solid #6169ff;
  width: 40px;
  height: 18px;
  font-size: 0.6em;
  align-items: center;
  justify-content: center;
  border-radius: 400px;
  padding: 1px;
  color: #6169ff;
}

.tittle_app {
  display: flex;
  margin-left: 30px;
  margin-bottom: 15px;
}
.contener p {
  color: #a6b0cf;
  font-size: 20px;
  margin-left: 4px;
  font-weight: bold;
}
.author-modal {
  font-size: 12px;
  color: #2f65ff;
  margin-bottom: 10px;
}

.app-icons {
  font-size: 18px;
  margin-bottom: 3px;
}

.information-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
}

.text-c {
  color: #a6b0cf;
  font-size: 18px;
  margin-left: 4px;
}

.Footer {
  border-top: 2px solid rgba(0, 0, 0, 0.15);
  padding-top: 20px;
  width: 90%;
  text-align: center;
  margin-bottom: 20px;
}

.text-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.text-title-c {
  font-size: 1.2em;
  font-weight: 600;
  margin-top: 2px;
}

.text-Footer {
  font-size: 1.1em;
  font-weight: 400;
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
    opacity: 0;
    /* Agrega esta línea */
  }

  100% {
    transform: translateY(0);
    opacity: 1;
    /* Agrega esta línea */
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    /* Estado inicial: completamente transparente */
  }

  to {
    opacity: 1;
    /* Estado final: completamente visible */
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    /* Estado inicial: tamaño normal */
  }

  50% {
    transform: scale(1.04);
    /* Escala al 110% */
  }

  100% {
    transform: scale(1);
    /* Vuelve al tamaño normal */
  }
}

/* Aplica la animación a tu elemento con clase .modal */
.modal {
  /* Estilos iniciales */
  animation: fade-in 1s ease;
  /* Aplica la animación de fade-in */
}
